import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "../../../interfaces";
import { isMobile } from "@/global/services/mixins";
import { units } from "@/global/services/units";
import { authentication } from "@/global/services/authentication";
import { errorLogging } from "@/global/services/errorLogging";
import { ref } from "vue";

const document = ref(null as any);
let documentDetacher: any = undefined;

export const employee: any = {
  update: function(uid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      documentDetacher === undefined ? null : documentDetacher();
      const ref = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/employees")
        .where("uid", "==", uid);
      documentDetacher = ref.onSnapshot((querySnap: any) => {
        querySnap.docChanges().forEach((change: any) => {
          document.value = change.doc;
        });
      });
      resolve();
    });
  },

  get: function(reference = false) {
    if (reference) {
      return document;
    } else return document.value;
  },

  set: function(employeeDoc: firebase.firestore.DocumentSnapshot, data: any) {
    employeeDoc.ref.update(data);
  },

  getId: function(): string | undefined {
    return document.value.id;
  },

  create: async function(data: Employee) {
    const func = firebase.functions().httpsCallable("createEmployee");
    return await func(data)
      .then((result: any) => {
        // Read result of the Cloud Function.
        return result;
      })
      .catch((error: any) => console.log("Functions return error", error));
  },

  formatIdToName: function(eid: string) {
    const employee = units
      .getSelectedUnitEmployees()
      .find((e: any) => e.id === eid);
    if (employee === undefined) {
      return "";
    }
    if (isMobile()) {
      return employee.data().firstName + " " + employee.data().lastName[0];
    } else return employee.data().firstName + " " + employee.data().lastName;
  },

  deleteEmployee: function(eid: string) {
    const emp = units.getSelectedUnitEmployees().find((e: any) => e.id === eid);
    if (employee === undefined) {
      return "";
    } else {
      const now = new Date();
      employee.set(emp, {
        deleted: firebase.firestore.Timestamp.fromDate(now),
        deletedBy: employee.getId()
      });
    }
  }
};
