const event1 = new CustomEvent("processing", { detail: true });
const event2 = new CustomEvent("processing", { detail: false });

const reAuthenticationEvent = new CustomEvent("reAuthenticate", {
  detail: true
});
const reAuthenticationCompleteEvent1 = new CustomEvent(
  "reAuthenticationComplete",
  {
    detail: true
  }
);
const reAuthenticationCompleteEvent2 = new CustomEvent(
  "reAuthenticationComplete",
  {
    detail: false
  }
);

export const systemState = {
  startProcessing: function() {
    document.dispatchEvent(event1);
  },
  stopProcessing: function() {
    document.dispatchEvent(event2);
  },
  dispatchReAuthentication: function() {
    document.dispatchEvent(reAuthenticationEvent);
  },
  dispatchReAuthenticationComplete: function() {
    document.dispatchEvent(reAuthenticationCompleteEvent1);
  },
  dispatchReAuthenticationAborted: function() {
    document.dispatchEvent(reAuthenticationCompleteEvent2);
  }
};
