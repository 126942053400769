import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "../../../interfaces";
import { ref } from "vue";
import { errorLogging } from "@/global/services/errorLogging";
import { db } from "@/global/services/db";
import { authentication } from "@/global/services/authentication";
import { period } from "../../timeregistration/services/period";

const userFarm = ref(undefined as firebase.firestore.DocumentData | undefined);
const allFarms = ref([] as Array<firebase.firestore.DocumentData>);
const monthReports = ref([] as Array<firebase.firestore.DocumentData>);
const monthReportsLength = ref(0);
let farmDetacher: any = undefined;
let allFarmsDetacher: any = undefined;
let monthReportsDetacher: any = undefined;

export const farm: any = {
  init: function(farmId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const farmsRef = firebase.firestore().collection("farms");
      const farmRef = farmsRef.doc(farmId);
      farmDetacher !== undefined ? farmDetacher() : null;

      farmDetacher = farmRef.onSnapshot((docSnap: any) => {
        if (docSnap.id === farmId) {
          farm.updateFarm(docSnap.data()).then(() => {
            farm.updateMonthReports();
            if (process.env.NODE_ENV !== "production") {
              errorLogging.setInfo("Farm listener started");
            }
            resolve();
          });
        }
      });
    });
  },

  set: function(data: any) {
    const farmsRef = firebase.firestore().collection("farms");
    const farmRef = farmsRef.doc(authentication.getFarmId());
    farmRef.update(data);
  },

  updateFarm: function(data: firebase.firestore.DocumentData): Promise<void> {
    return new Promise((resolve, reject) => {
      userFarm.value = data;
      resolve();
    });
  },

  getFarmName: function() {
    return userFarm.value?.companyName;
  },

  getFarm: function(reference = false) {
    if (reference) {
      return userFarm;
    } else {
      return userFarm.value;
    }
  },

  getOwnerUid: function(reference = false) {
    return userFarm.value?.ownerId;
  },

  getAllFarms: function(reference = false) {
    if (reference) {
      return allFarms;
    } else {
      return allFarms.value;
    }
  },

  updateAllFarms: function() {
    // Optimize so it only get called when nothing in cache
    const allFarmsRef = firebase.firestore().collection("farms");
    if (allFarmsDetacher !== undefined) {
      allFarmsDetacher();
    }
    allFarmsDetacher = allFarmsRef.onSnapshot((snap: any) => {
      snap.docChanges().forEach(
        (change: any) => {
          const farm = change.doc.data();
          farm["id"] = change.doc.id;
          if (change.type === "added") {
            allFarms.value.push(farm);
          }
          if (change.type === "modified") {
            const idx = allFarms.value.findIndex((x: any) => x.id === farm.id);
            allFarms.value.splice(idx, 1, farm);
          }
          if (change.type === "removed") {
            const idx = allFarms.value.findIndex((x: any) => x.id === farm.id);
            allFarms.value.splice(idx, 1);
          }
        },
        (error: any) => console.error(error)
      );
    });
  },

  updateUsingMultipleUnits: function(value: boolean) {
    const oldFarmObj = userFarm.value;
    oldFarmObj ? (oldFarmObj["multipleUnits"] = !value) : null;
    return new Promise((resolve, reject) => {
      const farmDocRef = firebase
        .firestore()
        .collection("farms")
        .doc(authentication.getFarmId());
      const data = {
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        multipleUnits: value
      };

      return farmDocRef
        .update(data)
        .then(() => {
          if (!oldFarmObj) {
            reject("No farm data");
          } else {
            resolve("Flere driftsenhedder er ændret");
          }
        })
        .catch((error: any) => reject(error.message));
    });
  },

  updateMonthReports: function() {
    monthReports.value = [];
    // Optimize so it only get called when nothing in cache
    const monthReportsRef = firebase
      .firestore()
      .collection("farms/" + authentication.getFarmId() + "/monthReports")
      .where("deleted", "==", null);
    if (monthReportsDetacher !== undefined) {
      monthReportsDetacher();
    }
    monthReportsDetacher = monthReportsRef.onSnapshot((snap: any) => {
      monthReportsLength.value = snap.size;
      console.log(
        `Received query snapshot for monthReports: size ${snap.size}`
      );
      snap.docChanges().forEach(
        (change: any) => {
          const report = change.doc;
          if (change.type === "added") {
            monthReports.value.push(report);
          }
          if (change.type === "modified") {
            const idx = monthReports.value.findIndex(
              (x: any) => x.id === report.id
            );
            monthReports.value.splice(idx, 1, report);
          }
          if (change.type === "removed") {
            const idx = monthReports.value.findIndex(
              (x: any) => x.id === report.id
            );
            monthReports.value.splice(idx, 1);
          }
        },
        (error: any) => console.error(error)
      );
    });
  },
  getMonthReports: function(reference = false) {
    if (reference) {
      return monthReports;
    } else {
      return monthReports.value;
    }
  },

  getMonthReportsLength: function() {
    return monthReportsLength.value;
  },

  getLatestMonthReport: function() {
    const periodStart = new Date(period.start.value);
    return periodStart.getMonth() !== 0
      ? monthReports.value.find(
          r =>
            r.data().month === periodStart.getMonth() - 1 &&
            r.data().year === periodStart.getFullYear()
        )
      : monthReports.value.find(
          r =>
            r.data().month === 11 &&
            r.data().year === periodStart.getFullYear() - 1
        );
  }
};
