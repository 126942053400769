import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "../../../interfaces";
import { errorLogging } from "@/global/services/errorLogging";
import { db } from "@/global/services/db";
import { authentication } from "@/global/services/authentication";
import { units } from "@/global/services/units";
import { period } from "@/timeregistration/services/period";
import { ref, computed } from "vue";

const timeRegistrationsInIntervalList = ref([] as any);
const timeRegistrationsInIntervalDataList = computed(() => {
  return timeRegistrationsInIntervalList.value.map((e: any) => e.data());
});
const numberOfTimeRegistrations = ref(undefined as number | undefined);

const userTimeRegistrationsList = ref([] as any);
const userTimeRegistrationsDataList = computed(() => {
  return userTimeRegistrationsList.value.map((e: any) => e.data());
});
const numberOfUserTimeRegistrations = ref(undefined as number | undefined);

let timeRegistrationsInIntervalDetacher: any = undefined;
let userTimeRegistrationsDetacher: any = undefined;

export const timeRegistration: any = {
  getTimeRegistrationsInIntervalList: function(
    reference = false
  ): any | undefined {
    if (reference) {
      return timeRegistrationsInIntervalList;
    } else return timeRegistrationsInIntervalList.value;
  },
  getTimeRegistrationsInIntervalDataList: function(
    reference = false
  ): any | undefined {
    if (reference) {
      return timeRegistrationsInIntervalDataList;
    } else return timeRegistrationsInIntervalDataList.value;
  },
  getNumberOfTimeRegistrations: function(): number | undefined {
    return numberOfTimeRegistrations.value;
  },

  getUserTimeRegistrationsList: function(
    reference = false
  ): string | undefined {
    if (reference) {
      return userTimeRegistrationsList;
    } else return userTimeRegistrationsList.value;
  },
  getUserTimeRegistrationsDataList: function(
    reference = false
  ): any | undefined {
    if (reference) {
      return userTimeRegistrationsDataList;
    } else return userTimeRegistrationsDataList.value;
  },
  getNumberOfUserTimeRegistrations: function(): number | undefined {
    return numberOfUserTimeRegistrations.value;
  },
  emptyMemory: function() {
    timeRegistrationsInIntervalList.value = [];
    userTimeRegistrationsList.value = [];
  },

  checkTimeExists: async function(time: any, id = "") {
    const startTimes = await firebase
      .firestore()
      .collection("farms/" + authentication.getFarmId() + "/timeRegistrations")
      .where("uid", "==", authentication.getUserUid())
      .where("start", ">=", time.start)
      .where("start", "<=", time.end)
      .get();
    const endTimes = await firebase
      .firestore()
      .collection("farms/" + authentication.getFarmId() + "/timeRegistrations")
      .where("uid", "==", authentication.getUserUid())
      .where("end", ">=", time.start)
      .where("end", "<=", time.end)
      .get();

    const s = startTimes.docs.filter(e => e.data().deleted === undefined);
    const e = endTimes.docs.filter(e => e.data().deleted === undefined);
    if (s.length === 0 && e.length === 0) {
      return false;
    } else {
      return !(
        (s.some(t => t.id === id) || e.some(t => t.id === id)) &&
        s.length <= 1 &&
        e.length <= 1
      );
    }
  },

  startTimeRegistration: function() {
    const time = Date.now();
    return new Promise((resolve, reject) => {
      if (authentication.getFarmId() && authentication.getUser()) {
        const timeRegistrationsRef = firebase
          .firestore()
          .collection(
            "farms/" + authentication.getFarmId() + "/timeRegistrations"
          );
        timeRegistrationsRef
          .add({
            uid: authentication.getUserUid(),
            start: time,
            end: false,
            unit: authentication.getUserSelectedUnit()
          })
          .then((doc: any) => {
            resolve("Tidregistrering startet.");
            if (process.env.NODE_ENV !== "production") {
              errorLogging.setInfo("Tidregistrering id: " + doc.id);
            }
          })
          .catch((error: any) => reject(error.message));
      } else {
        reject("Ingen bruger/gård tilgængelig");
      }
    });
  },

  startTimeRegistrationWithGPSLog: function(lat: any, lng: any) {
    const time = Date.now();
    return new Promise((resolve, reject) => {
      if (authentication.getFarmId() && authentication.getUser()) {
        const timeRegistrationsRef = firebase
          .firestore()
          .collection(
            "farms/" + authentication.getFarmId() + "/timeRegistrations"
          );
        timeRegistrationsRef
          .add({
            uid: authentication.getUserUid(),
            start: time,
            end: false,
            unit: authentication.getUserSelectedUnit(),
            startGps: [lat, lng]
          })
          .then((doc: any) => {
            resolve("Tidregistrering startet.");
            if (process.env.NODE_ENV !== "production") {
              errorLogging.setInfo("Tidregistrering id: " + doc.id);
            }
          })
          .catch((error: any) => reject(error.message));
      } else {
        reject("Ingen bruger/gård tilgængelig");
      }
    });
  },

  addTimeRegistration: function(
    startDate: number,
    endDate: number,
    uid: string
  ) {
    if (authentication.getFarmId() && authentication.getUser()) {
      const timeRegistrationsRef = firebase
        .firestore()
        .collection(
          "farms/" + authentication.getFarmId() + "/timeRegistrations"
        );
      timeRegistrationsRef
        .add({
          uid: uid,
          start: startDate,
          end: endDate,
          unit: authentication.getUserSelectedUnit()
        })
        .then((doc: any) => {
          errorLogging.setSuccess("Tidregistrering tilføjet.");
          if (process.env.NODE_ENV !== "production") {
            errorLogging.setInfo("Tidregistrering id: " + doc.id);
          }
        })
        .catch((error: any) => errorLogging.setError(error.message));
    } else errorLogging.setError("Ingen bruger/gård tilgængelig");
  },

  deleteTimeRegistration: function(id: string) {
    const timeRegistrationsRef = firebase
      .firestore()
      .collection("farms/" + authentication.getFarmId() + "/timeRegistrations");
    timeRegistrationsRef
      .doc(id)
      .update({ deleted: true })
      .then(() => {
        errorLogging.setSuccess("Tidregistrering slettet.");
      })
      .catch((error: any) => errorLogging.setError(error.message));
  },

  changeTimeRegistration: function(timeChanged: any, timeChangedData: any) {
    console.log("Time change:", timeChanged.id);
    return new Promise((resolve, reject) => {
      db.updateDocument(timeChanged, timeChangedData)
        .then(() => resolve("Ændring er registreret"))
        .catch((error: any) => reject(error.message));
    });
  },

  stopTimeRegistration: function(
    id: string,
    breakDuration: number,
    note: string
  ) {
    const time = Date.now();
    return new Promise((resolve, reject) => {
      const timeRegistrationsRef = firebase
        .firestore()
        .collection(
          "farms/" + authentication.getFarmId() + "/timeRegistrations"
        );
      timeRegistrationsRef
        .doc(id)
        .set(
          {
            breakDuration,
            note,
            end: time
          },
          { merge: true }
        )
        .then(() => {
          resolve("Tidregistrering stoppet");
        })
        .catch((error: any) => reject(error.message));
    });
  },

  stopTimeRegistrationWithGPSLog: function(
    id: string,
    breakDuration: number,
    note: string,
    lat: any,
    lng: any
  ) {
    const time = Date.now();
    return new Promise((resolve, reject) => {
      const timeRegistrationsRef = firebase
        .firestore()
        .collection(
          "farms/" + authentication.getFarmId() + "/timeRegistrations"
        );
      timeRegistrationsRef
        .doc(id)
        .set(
          {
            breakDuration,
            note,
            end: time,
            stopGps: [lat, lng]
          },
          { merge: true }
        )
        .then(() => {
          resolve("Tidregistrering stoppet");
        })
        .catch((error: any) => reject(error.message));
    });
  },

  getTimeStarted: function() {
    return new Promise((resolve, reject) => {
      if (authentication.getFarmId() && authentication.getUser()) {
        const timeRegistrationsRef = firebase
          .firestore()
          .collection(
            "farms/" + authentication.getFarmId() + "/timeRegistrations"
          )
          .where("uid", "==", authentication.getUserUid())
          .where("end", "==", false);
        timeRegistrationsRef
          .get()
          .then((querySnap: any) => {
            if (!querySnap.empty) {
              const time = querySnap.docs[0];
              resolve(time);
            } else {
              resolve(false);
            }
            if (process.env.NODE_ENV !== "production") {
              errorLogging.setInfo(
                "FB - Tidregistrering findes: " + !querySnap.empty
              );
            }
          })
          .catch((error: any) => reject(error.message));
      } else {
        reject("Ingen bruger/gård tilgængelig");
      }
    });
  },

  // Code for week reports
  /* getAll: async function(ref: any) {
    const timeRegistrationsRef = firebase
      .firestore()
      .collection("farms/" + authentication.getFarmId() + "/timeRegistrations")
      .get();

    let t: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
    [t] = await Promise.all([timeRegistrationsRef]);
    ref.value = t.docs;
  }, */

  updateTimeRegistrationsInInterval: function() {
    timeRegistrationsInIntervalList.value = [];
    if (authentication.getFarmId() && authentication.getUser()) {
      if (timeRegistrationsInIntervalDetacher !== undefined) {
        timeRegistrationsInIntervalDetacher();
      }
      const timeRegistrationsRef = firebase
        .firestore()
        .collection(
          "farms/" + authentication.getFarmId() + "/timeRegistrations"
        )
        .where("start", ">=", period.start.value)
        .where("start", "<=", period.end.value)
        .where("unit", "==", units.getSelectedUnit().data().unitName)
        .orderBy("start", "desc");

      timeRegistrationsInIntervalDetacher = timeRegistrationsRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot timeRegistrationsInInterval: size ${querySnap.size}`
          );
          numberOfTimeRegistrations.value = querySnap.size;
          querySnap.docChanges().forEach(
            (change: any) => {
              if (change.type === "added") {
                timeRegistrationsInIntervalList.value.push(change.doc);
              }
              if (change.type === "modified") {
                const idx = timeRegistrationsInIntervalList.value.findIndex(
                  (x: any) => x.id === change.doc.id
                );
                timeRegistrationsInIntervalList.value.splice(
                  idx,
                  1,
                  change.doc
                );
              }

              if (change.type === "removed") {
                const idx = timeRegistrationsInIntervalList.value.findIndex(
                  (x: any) => x.id === change.doc.id
                );
                timeRegistrationsInIntervalList.value.splice(idx, 1);
              }
            },
            (error: any) => console.log(error)
          );
        },
        (error: any) => console.log(error)
      );
    } else errorLogging.setError("Ingen bruger/gård tilgængelig");
  },

  updateUserTimes: function() {
    if (authentication.getFarmId() && authentication.getUser()) {
      if (userTimeRegistrationsDetacher !== undefined) {
        userTimeRegistrationsDetacher();
      }
      userTimeRegistrationsList.value = [];
      const userTimeRegistrationsRef = firebase
        .firestore()
        .collection(
          "farms/" + authentication.getFarmId() + "/timeRegistrations"
        )
        .where("start", ">=", period.start.value)
        .where("start", "<=", period.end.value)
        .where("unit", "==", units.getSelectedUnit().data().unitName)
        .where("uid", "==", authentication.getUserUid())
        .orderBy("start", "desc");
      userTimeRegistrationsDetacher = userTimeRegistrationsRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot userTimeRegistrations: size ${querySnap.size}`
          );
          numberOfUserTimeRegistrations.value = querySnap.size;
          querySnap.docChanges().forEach(
            (change: any) => {
              const registration = change.doc;

              if (change.type === "added") {
                userTimeRegistrationsList.value.push(registration);
              }
              if (change.type === "modified") {
                const idx = userTimeRegistrationsList.value.findIndex(
                  (x: any) => x.id === registration.id
                );
                userTimeRegistrationsList.value.splice(idx, 1, registration);
              }

              if (change.type === "removed") {
                const idx = userTimeRegistrationsList.value.findIndex(
                  (x: any) => x.id === registration.id
                );
                userTimeRegistrationsList.value.splice(idx, 1);
              }
            },
            (error: any) => console.log(error)
          );
        },
        (error: any) => console.log(error)
      );
    } else errorLogging.setError("Ingen bruger/gård tilgængelig");
  },

  getRegistrationHistory: function(id: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      const list: any = [];
      const registrationHistoryRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/logs")
        .where("documentId", "==", id)
        .orderBy("created", "desc");

      registrationHistoryRef
        .get()
        .then((querySnap: any) => {
          querySnap.forEach((r: any) => {
            list.push(r.data());
          });
        })
        .catch((error: any) => errorLogging.setError(error.message))
        .finally(() =>
          resolve(list.filter((reg: any) => reg.before.end !== false))
        );
    });
  }
};
