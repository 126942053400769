interface Employee {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  farmUnits: Array<string> | undefined;
}

interface Claims {
  farm: string | undefined;
  admin: boolean;
  owner: boolean;
  leader: boolean;
}

module.exports.Employee;

module.exports.Claims;
