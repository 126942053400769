import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "../../../interfaces";
import { errorLogging } from "@/global/services/errorLogging";
import { ref, watchEffect } from "vue";
import { systemState } from "./systemState";
import { employee } from "./employee";

const userProfile = ref(null as any);
const userClaims = ref({
  admin: false,
  owner: false,
  farm: undefined,
  leader: false
} as Claims);

export const authentication: any = {
  signIn: function(email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => resolve())
        .catch(error => {
          const errorObject = {
            code: error.code,
            message: error.message
          };
          if (error.code === "auth/user-not-found") {
            errorObject.message = "Denne bruger findes ikke";
          } else if (error.code === "auth/invalid-email") {
            errorObject.message = "Indtast venligst en gyldig e-mail";
          } else if (error.code === "auth/user-disabled") {
            errorObject.message = "Denne bruger er blevet deaktiveret";
          } else if (error.code === "auth/wrong-password") {
            errorObject.message = "Indtast venligst den korrekte kode";
          } else if (error.code === "auth/too-many-requests") {
            errorObject.message =
              "Der er blevet registreret for mange login forsøg. Kontoen er midlertidig spærret. Prøv igen om 5 minutter. Har du glemt din kode så anmod om en ny kode.";
          }

          reject(errorObject);
        });
    });
  },

  reAuthenticate: function(password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const email = firebase.auth().currentUser?.email;
      return firebase
        .auth()
        .signInWithEmailAndPassword(email ? email : "", password)
        .then(() => resolve())
        .catch(error => {
          const errorObject = {
            code: error.code,
            message: error.message
          };
          if (error.code === "auth/user-not-found") {
            errorObject.message = "Denne bruger findes ikke";
          } else if (error.code === "auth/invalid-email") {
            errorObject.message = "Indtast venligst en gyldig e-mail";
          } else if (error.code === "auth/user-disabled") {
            errorObject.message = "Denne bruger er blevet deaktiveret";
          } else if (error.code === "auth/wrong-password") {
            errorObject.message = "Indtast venligst den korrekte kode";
          } else if (error.code === "auth/too-many-requests") {
            errorObject.message =
              "Der er blevet registreret for mange login forsøg. Kontoen er midlertidig spærret. Prøv igen om 5 minutter. Har du glemt din kode så anmod om en ny kode.";
          }

          reject(errorObject);
        });
    });
  },

  updateUser: function(uid: string, data: any): Promise<void> {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .update(data)
        .then(() => resolve())
        .catch((error: any) => reject(error));
    });
  },

  signOut: function() {
    const uid = firebase.auth().currentUser?.uid;
    if (userClaims.value.admin) {
      firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .update({ selectedUnit: "", units: [], farm: "" })
        .then(() =>
          firebase
            .auth()
            .signOut()
            .then(() => {
              location.reload();
            })
        );
    } else {
      firebase
        .auth()
        .signOut()
        .then(() => {
          location.reload();
        });
    }
  },

  isOwner: function() {
    if (userClaims.value.owner || userClaims.value.admin) {
      return true;
    } else {
      return false;
    }
  },

  isLeader: function() {
    if (userClaims.value.leader) {
      return true;
    } else {
      return false;
    }
  },

  isEmployee: function() {
    if (!userClaims.value.leader && !userClaims.value.owner) {
      return true;
    } else {
      return false;
    }
  },

  getJobTitle: function() {
    if (userClaims.value.owner) {
      return "owner";
    } else if (userClaims.value.leader) {
      return "leader";
    } else {
      return "employee";
    }
  },

  isAdmin: function() {
    return userClaims.value.admin;
  },

  getUserProfile: function(reference = false) {
    if (reference) {
      return userProfile;
    } else return userProfile.value;
  },

  getUserFCMTokens: function() {
    if (userProfile.value.fcmTokens) {
      return userProfile.value.fcmTokens;
    } else {
      return [];
    }
  },

  getUser: function() {
    return firebase.auth().currentUser;
  },

  getFarmId: function(): string | undefined {
    return userClaims.value.farm;
  },

  updateClaims: function(token: any) {
    userClaims.value = {
      admin: token.claims.admin ? true : false,
      farm: token.claims.farm !== undefined ? token.claims.farm : undefined,
      owner: token.claims.owner ? true : false,
      leader: token.claims.leader ? true : false
    };
  },

  updateUserProfile: function(
    data: firebase.firestore.DocumentData
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      userProfile.value = data;
      resolve();
    });
  },

  getUserUid: function(): string | undefined {
    return firebase.auth().currentUser?.uid;
  },

  getUserSelectedUnit: function(): string | undefined {
    return userProfile.value?.selectedUnit;
  },

  /**
   * Creates a log of what happened to a document
   * @param unitName Name of the unit the user has selected
   * @returns "Log created" if success or error message on error
   */
  updateUserSelectedUnit: function(unitName: string): Promise<string> {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser !== null) {
        return firebase
          .firestore()
          .collection("users")
          .doc(authentication.getUserUid())
          .set(
            {
              selectedUnit: unitName
            },
            { merge: true }
          )
          .then(() => resolve("Driftsenhed ændret"))
          .catch((error: any) => reject(error));
      } else reject("Ingen bruger tilgængelig");
    });
  },

  changePassword: function(password1: string, password2: string) {
    import(
      /* webpackExports: ["default", "validatePassword"] */ "@/global/services/mixins"
    ).then(module => {
      const { validatePassword } = module;
      if (password1 !== password2) {
        errorLogging.setWarning("De to koder er ikke ens");
      } else if (validatePassword(password1)) {
        const user = firebase.auth().currentUser;
        if (user !== null) {
          user
            .updatePassword(password1)
            .then(() => {
              const usersRef = firebase.firestore().collection("users");
              usersRef.doc(user.uid).set(
                {
                  firstSignIn: false
                },
                { merge: true }
              );
              errorLogging.setSuccess("Koden er ændret");
            })
            .catch((e: any) => {
              /* eslint-disable */
              if (e.code === "auth/requires-recent-login") {
                systemState.dispatchReAuthentication();
                document.addEventListener("reAuthenticationComplete", (event: any) => {
                  if (event.detail) {
                    user
                      .updatePassword(password1)
                      .then(() => {
                        const usersRef = firebase.firestore().collection("users");
                        usersRef.doc(user.uid).set(
                          {
                            firstSignIn: false
                          },
                          { merge: true }
                        );
                        errorLogging.setSuccess("Koden er ændret");
                        document.removeEventListener(
                          "reAuthenticationComplete",
                          () => {}
                        );
                    });
                  } else {
                    document.removeEventListener(
                      "reAuthenticationComplete",
                      () => {}
                    );
                  }
                });
              } else {
                errorLogging.setError(
                  e.message,
                  "page-user-profile.vue",
                  "page-user-profile",
                  "Line: 262"
                );
              }
            });
        }
      } else {
        errorLogging.setWarning("Koden opfylder ikke kravene");
      }
    });
  },

  createLeader: async function(employeeDoc: any) {
    console.log("Create leader", employeeDoc.data().leader);
    systemState.startProcessing();
    const func = firebase.functions().httpsCallable("createLeader");
    let data: any;
    if (employeeDoc.data().leader) {
      data = { uid: employeeDoc.data().uid, leader: false };
    } else {
      data = { uid: employeeDoc.data().uid, leader: true };
    }
    return await func(data)
      .then((result: any) => {
        systemState.stopProcessing();
        // Read result of the Cloud Function.
        return result;
      })
      .catch((error: any) => {
        systemState.stopProcessing();
        console.log("Functions return error", error.message);
      });
  }
};

watchEffect(() => {
  if (employee.get() !== null && userClaims.value.farm !== undefined) {
    if (employee.get().data().leader !== undefined) {
      if (employee.get().data().leader !== authentication.isLeader()) {
        authentication.signOut();
      }
    }
  }
});
 