import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import firebase from "firebase/app";
import "firebase/auth";
import { analytics } from "@/global/services/analytics";
import { isMobile } from "@/global/services/mixins";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/global/page-login.vue")
  },
  {
    path: "/Create",
    name: "Create",
    component: () => import("@/global/page-create.vue")
  },
  {
    path: "/ServiceLogin",
    name: "ServiceLogin",
    component: () => import("@/global/page-login-service.vue")
  },
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("@/global/page-reset-password.vue")
  },

  {
    path: "",
    name: "DashboardLayout",
    component: () => import("@/global/app-layout.vue"),
    children: [
      {
        path: "UserProfile",
        name: "UserProfile",
        component: () => import("@/global/page-user-profile.vue")
      },
      {
        path: "Chart",
        name: "Chart",
        component: () => import("@/components/Chart.vue")
      },
      {
        path: "Dashboard",
        name: "Dashboard",
        component: () => import("@/global/page-dashboard.vue")
      },
      {
        path: "Requests",
        name: "Requests",
        component: () => import("@/timeregistration/page-timeregistration-requests.vue")
      },
      {
        path: "Settings",
        name: "Settings",
        component: () => import("@/global/page-farm-settings.vue"),
        children: [
          {
            path: "General",
            name: "SettingsGeneral",
            component: () => import("@/global/page-farm-settings-general.vue")
          },
          {
            path: "Employees",
            name: "SettingsEmployees",
            component: () => import("@/global/page-farm-settings-employees.vue")
          },
          {
            path: "Units",
            name: "SettingsUnits",
            component: () => import("@/global/page-farm-settings-units.vue")
          }
        ]
      },
      {
        path: "Clock",
        name: "TimeRegistration",
        component: () => import("@/timeregistration/page-timeregistration-clock.vue")
      },
      {
        path: "Registrations",
        name: "Registrations",
        component: () => import("@/timeregistration/page-timeregistration-registrations.vue")
      },
      {
        path: "Registrations/:employeeId",
        name: "RegistrationsWithId",
        component: () => import("@/timeregistration/page-timeregistration-registrations.vue")
      },
      {
        path: "Vacations",
        name: "Vacations",
        component: () => import("@/timeregistration/page-timeregistration-vacations.vue")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(user => {
    if (to.name === "ResetPassword") {
      next();
    } else if (to.name === "Create" && user === null) {
      next();
    } else if (to.name !== "Login" && user === null) {
      next({ name: "Login" });
    } else if (to.name === "Login" && user !== null) {
      next({ name: "TimeRegistration" });
    } else if (to.name === "DashboardLayout" && user !== null) {
      next({ name: "TimeRegistration" });
    } else if (to.name === "Dashboard" && isMobile()) {
      next({ name: "TimeRegistration" });
    } else {
      next();
    }
  });
  if (to.name === "Settings") {
    next({ name: "SettingsGeneral" });
  }
});
router.afterEach((to, from, failure) => {
    analytics.setScreen(to);
});

export default router;
