/* eslint-disable @typescript-eslint/camelcase */

import firebase from "firebase/app";
import "firebase/analytics";

let analyticsInstance: any;

function isPWA() {
  return window && window.matchMedia("(display-mode: standalone)").matches;
}

export const analytics: any = {
  init: function() {
    if (process.env.NODE_ENV !== "development") {
      analyticsInstance = firebase.analytics();
    }
  },
  setScreen: function(to: any) {
    if (analyticsInstance) {
      analyticsInstance.setCurrentScreen(to.name);
      analyticsInstance.logEvent("page_view", {
        page_location: "https://app.farmhelp.dk",
        page_path: to.path,
        page_title: to.name
      });
      analyticsInstance.logEvent("screen_view", {
        app_name: isPWA() ? "pwa" : "web",
        screen_name: to.name,
        app_version: "v1.0.2"
      });
    } else {
      console.log("analytics is undefined");
    }
  },
  logError: function(description: string, fatal: boolean) {
    if (analyticsInstance) {
      analyticsInstance.logEvent("exeption", {
        description,
        fatal
      });
    }
  }
};
