import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "../../../interfaces";
import { ref, watchEffect, computed } from "vue";
import { authentication } from "@/global/services/authentication";
import { errorLogging } from "@/global/services/errorLogging";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { db } from "@/global/services/db";

const unitsList = ref(
  [] as Array<firebase.firestore.DocumentSnapshot | undefined>
);
const numberOfUnits = ref(undefined as number | undefined);
const selectedUnit = ref(
  undefined as firebase.firestore.DocumentSnapshot | undefined
);
const unitsDataList = computed(() => {
  return unitsList.value.map((e: any) => e.data());
});
const selectedUnitEmployeesList = ref([] as any);
const farmUsersList = ref([] as any);
const selectedUnitEmployeesListLength = ref(undefined as any);
let selectedUnitEmployeesListDetacher: any = undefined;
let farmUsersListDetacher: any = undefined;
let unitsDetacher: any = undefined;

export const units: any = {
  init: function() {
    unitsDetacher !== undefined ? unitsDetacher() : null;
    const unitsCollectionRef = firebase
      .firestore()
      .collection("farms/" + authentication.getFarmId() + "/units");
    if (process.env.NODE_ENV !== "production") {
      errorLogging.setInfo("Units listener started");
    }
    unitsDetacher = unitsCollectionRef.onSnapshot((querySnap: any) => {
      console.log(`Received query snapshot units: size ${querySnap.size}`);
      numberOfUnits.value = querySnap.size;
      querySnap.docChanges().forEach(
        (docChange: any) => {
          const docReference = docChange.doc;
          if (docChange.type === "added") {
            units.getUnitsList().push(docReference);
          }
          if (docChange.type === "modified") {
            const idx = units
              .getUnitsList()
              .findIndex((x: any) => x.id === docReference.id);
            units.getUnitsList().splice(idx, 1, docReference);
          }
          if (docChange.type === "removed") {
            const idx = units
              .getUnitsList()
              .findIndex((x: any) => x.id === docReference.id);
            units.getUnitsList().splice(idx, 1);
          }
        },
        (error: any) => errorLogging.setError(error.message)
      );
    });
    watchEffect(() => {
      if (
        authentication.getUserProfile() !== null &&
        units.getUnitsList().length === numberOfUnits.value
      ) {
        const unit = unitsList.value.find(
          e => e?.data()?.unitName === authentication.getUserSelectedUnit()
        );
        const idx = unitsList.value.indexOf(unit);
        selectedUnit.value = unitsList.value[idx];
        units.updateSelectedUnitEmployees();
        units.updateFarmUsers();
      }
    });
  },

  getUnitsList: function(reference = false) {
    if (reference) {
      return unitsList;
    } else return unitsList.value;
  },

  getUnitsDataList: function(reference = false) {
    if (reference) {
      return unitsDataList;
    } else return unitsDataList.value;
  },

  createUnit: function(data: any): Promise<void> {
    const dataCopy = Object.assign({}, data);
    dataCopy.created = firebase.firestore.FieldValue.serverTimestamp();

    return new Promise((resolve, reject) => {
      db.createDocument(
        dataCopy,
        "farms/" + authentication.getFarmId() + "/units"
      )
        .then(() => {
          resolve();
          errorLogging.setSuccess("Driftsenhed tilføjet");
        })
        .catch((error: any) => {
          reject();
          errorLogging.setError(error);
        });
    });
  },

  updateSelectedUnit: function(unit: firebase.firestore.DocumentSnapshot) {
    return new Promise((resolve, reject) => {
      timeRegistration.emptyMemory();
      return authentication
        .updateUserSelectedUnit(unit.data()?.unitName)
        .then((message: string) => resolve(message))
        .catch((error: any) => reject(error));
    });
  },

  updateSelectedUnitEmployees: function() {
    selectedUnitEmployeesList.value = [];
    // Optimize so it only get called when nothing in cache
    const unitEmployeesRef = firebase
      .firestore()
      .collection("farms/" + authentication.getFarmId() + "/employees")
      .where("units", "array-contains", selectedUnit.value?.id);
    if (selectedUnitEmployeesListDetacher !== undefined) {
      selectedUnitEmployeesListDetacher();
    }
    selectedUnitEmployeesListDetacher = unitEmployeesRef.onSnapshot(
      (snap: any) => {
        console.log(
          `Received query snapshot for selectedUnitEmployees: size ${snap.size}`
        );
        snap.docChanges().forEach(
          (change: any) => {
            selectedUnitEmployeesListLength.value = snap.size;
            if (change.type === "added") {
              selectedUnitEmployeesList.value.push(change.doc);
            }
            if (change.type === "modified") {
              console.log("Selected employee: ", change.doc.data().firstName);
              const idx = selectedUnitEmployeesList.value.findIndex(
                (x: any) => x.id === change.doc.id
              );
              selectedUnitEmployeesList.value.splice(idx, 1, change.doc);
            }
            if (change.type === "removed") {
              const idx = selectedUnitEmployeesList.value.findIndex(
                (x: any) => x.id === change.doc.id
              );
              selectedUnitEmployeesList.value.splice(idx, 1);
            }
          },
          (error: any) => console.error(error)
        );
      }
    );
    if (process.env.NODE_ENV !== "production") {
      errorLogging.setInfo("FB - Farm unit users list");
    }
  },

  updateFarmUsers: function() {
    // Optimize so it only get called when nothing in cache
    const farmUsersRef = firebase
      .firestore()
      .collection("users")
      .where("farm", "==", authentication.getFarmId());
    if (farmUsersListDetacher !== undefined) {
      farmUsersListDetacher();
    }
    farmUsersListDetacher = farmUsersRef.onSnapshot((snap: any) => {
      console.log(
        `Received query snapshot for farmUnitUsers: size ${snap.size}`
      );
      snap.docChanges().forEach(
        (change: any) => {
          if (change.doc.id !== authentication.getUserUid()) {
            const user = change.doc.data();
            user["uid"] = change.doc.id;
            if (change.type === "added") {
              farmUsersList.value.push(user);
            }
            if (change.type === "modified") {
              const idx = farmUsersList.value.findIndex(
                (x: any) => x.id === user.id
              );
              farmUsersList.value.splice(idx, 1, user);
            }
            if (change.type === "removed") {
              const idx = farmUsersList.value.findIndex(
                (x: any) => x.id === user.id
              );
              farmUsersList.value.splice(idx, 1);
            }
          }
        },
        (error: any) => console.error(error)
      );
    });
    if (process.env.NODE_ENV !== "production") {
      errorLogging.setInfo("FB - Farm users list");
    }
  },

  getSelectedUnitEmployees: function(reference = false) {
    if (reference) {
      return selectedUnitEmployeesList;
    } else return selectedUnitEmployeesList.value;
  },
  getSelectedUnitEmployeesLength: function(reference = false) {
    if (reference) {
      return selectedUnitEmployeesListLength;
    } else return selectedUnitEmployeesListLength.value;
  },

  getFarmUsers: function(reference = false) {
    if (reference) {
      return farmUsersList;
    } else return farmUsersList.value;
  },

  getSelectedUnit: function(reference = false) {
    if (reference) {
      return selectedUnit;
    } else return selectedUnit.value;
  },
  getSelectedUnitId: function() {
    return selectedUnit.value?.id;
  },

  getOwnerRegistersTime: function() {
    return selectedUnit.value?.data()?.ownerRegistersTime;
  },

  updateOwnerRegistersTime: function(
    unit: firebase.firestore.DocumentSnapshot
  ) {
    const newData = Object.assign({}, unit.data());
    newData.updated = firebase.firestore.FieldValue.serverTimestamp();
    const oldData = Object.assign({}, unit.data());
    newData.ownerRegistersTime = !newData.ownerRegistersTime;
    db.updateDocument(unit, newData, oldData, "units");
  },

  updateUsingIp: function(unit: firebase.firestore.DocumentSnapshot) {
    const newData = Object.assign({}, unit.data());
    newData.updated = firebase.firestore.FieldValue.serverTimestamp();
    const oldData = Object.assign({}, unit.data());
    newData.usingIp = !newData.usingIp;

    db.updateDocument(unit, newData, oldData, "units");
  },

  updateUsingGps: function(unit: firebase.firestore.DocumentSnapshot) {
    const newData = Object.assign({}, unit.data());
    newData.updated = firebase.firestore.FieldValue.serverTimestamp();
    const oldData = Object.assign({}, unit.data());
    newData.usingGps = !newData.usingGps;

    db.updateDocument(unit, newData, oldData, "units");
  },

  updateUsingGpsLog: function(unit: firebase.firestore.DocumentSnapshot) {
    const newData = Object.assign({}, unit.data());
    newData.updated = firebase.firestore.FieldValue.serverTimestamp();
    const oldData = Object.assign({}, unit.data());
    newData.usingGpsLog = !newData.usingGpsLog;

    db.updateDocument(unit, newData, oldData, "units");
  },

  updateIpAddress: function(
    unit: firebase.firestore.DocumentSnapshot,
    ipAddress: string
  ) {
    const newData = Object.assign({}, unit.data());
    newData.updated = firebase.firestore.FieldValue.serverTimestamp();
    const oldData = Object.assign({}, unit.data());
    newData.ipAddress = ipAddress;

    db.updateDocument(unit, newData, oldData, "units");
  },

  updateGpsCoordinates: function(
    unit: firebase.firestore.DocumentSnapshot,
    gpsBounds1: any,
    gpsBounds2: any
  ) {
    const newData = Object.assign({}, unit.data());
    newData.updated = firebase.firestore.FieldValue.serverTimestamp();
    const oldData = Object.assign({}, unit.data());
    newData.gpsBounds1 = gpsBounds1;
    newData.gpsBounds2 = gpsBounds2;

    db.updateDocument(unit, newData, oldData, "units");
  }
};
