import { ref, watchEffect } from "vue";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { farm } from "@/global/services/farm";

const userFarm = farm.getFarm(true);

const start = new Date();
const end = new Date();
if (start.getDate() < 15) {
  start.setMonth(start.getMonth() - 1);
} else {
  end.setMonth(end.getMonth() + 1);
}
start.setDate(15 + 1);
start.setHours(0, 0, 0, 1);
end.setDate(15);
end.setHours(23, 59, 59, 999);

export const period = {
  start: ref(start.getTime()),
  end: ref(end.getTime()),
  endPeriodDate: ref(),
  getPeriodDateList: function() {
    function addDays(date: Date, days: number) {
      const dat = new Date(date.getTime());
      dat.setDate(dat.getDate() + days);
      return dat;
    }

    const dateArray = [];
    let currentDate = new Date(period.start.value);
    const stopDate = new Date(period.end.value);
    console.log("Check", currentDate, stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(currentDate.toISOString());
      currentDate = addDays(currentDate, 1);
    }
    return dateArray;
  },
  decreasePeriod: function() {
    const ds = new Date(period.start.value);
    const de = new Date(period.end.value);
    ds.setMonth(ds.getMonth() - 1);
    de.setMonth(de.getMonth() - 1);
    period.start.value = ds.getTime();
    period.end.value = de.getTime();
  },
  increasePeriod: function() {
    if (!(period.end.value === period.endPeriodDate.value)) {
      const ds = new Date(period.start.value);
      const de = new Date(period.end.value);
      ds.setMonth(ds.getMonth() + 1);
      de.setMonth(de.getMonth() + 1);
      period.start.value = ds.getTime();
      period.end.value = de.getTime();
    }
  },
  isEndPeriod: function() {
    return period.end.value === period.endPeriodDate.value;
  }
};

watchEffect(() => {
  const d = new Date(period.end.value);
  d.setHours(23, 59, 59, 999);
  period.end.value = d.getTime();
  console.log(
    "Period",
    new Date(period.start.value),
    new Date(period.end.value)
  );
  if (timeRegistration !== undefined) {
    timeRegistration.emptyMemory();
  }
});

watchEffect(() => {
  if (userFarm.value !== undefined) {
    const now = new Date();
    const dStart = new Date();
    const dEnd = new Date();
    dStart.setDate(userFarm.value.wagePeriodEndDate);
    dEnd.setDate(userFarm.value.wagePeriodEndDate);
    if (now.getDate() <= userFarm.value.wagePeriodEndDate) {
      dStart.setMonth(dStart.getMonth() - 1);
    } else {
      dEnd.setMonth(dEnd.getMonth() + 1);
    }
    dStart.setDate(userFarm.value.wagePeriodEndDate + 1);
    dStart.setHours(0, 0, 0, 1);
    dEnd.setDate(userFarm.value.wagePeriodEndDate);
    dEnd.setHours(23, 59, 59, 999);
    period.start.value = dStart.getTime();
    period.end.value = dEnd.getTime();
    period.endPeriodDate.value = dEnd.getTime();
  }
});
