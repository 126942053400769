import firebase from "firebase/app";
import "firebase/firestore";
import "../../../interfaces";

export const db: any = {
  /**
   * Creates a document in the database
   * @param data The data the document should contain
   * @param collection Name of the collection inside the farm document that the document should be created in
   * @returns "Document added" if success or error on fail
   */
  createDocument: function(data: any, collection: string) {
    const dataCopy = Object.assign({}, data);
    dataCopy.created = firebase.firestore.FieldValue.serverTimestamp();

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(collection)
        .add(dataCopy)
        .then(() => {
          resolve("Document added");
        })
        .catch((error: any) => reject(error));
    });
  },

  updateDocument: function(
    documentSnapshot: firebase.firestore.DocumentSnapshot,
    newData: any
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      newData.updated = firebase.firestore.FieldValue.serverTimestamp();
      return documentSnapshot.ref.update(newData).then(() => {
        resolve();
      });
    });
  }
};
