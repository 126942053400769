/* eslint-disable @typescript-eslint/camelcase */

import firebase from "firebase/app";
import "firebase/firebase-messaging";
import { authentication } from "@/global/services/authentication";
import { errorLogging } from "./errorLogging";

export const notifications: any = {
  init: function() {
    /* const msg = firebase.messaging();
    Notification.requestPermission()
      .then(permission => {
        if (permission === "granted") {
          msg
            .getToken({
              vapidKey:
                "BEp9-WCZ5DRhseYxBGmLRjr1d741u3Z0gOfn68t3hnt6Aq8UZO3DFNnOnFZ_sWyz4Kgr0KUdpIwbUoWD8kQlyRg"
            })
            .then((currentToken: any) => {
              if (currentToken) {
                const currentTokens = authentication.getUserFCMTokens();
                currentTokens.push(currentToken);
                authentication
                  .updateUser(authentication.getUserUid(), {
                    tokens: currentTokens
                  })
                  .catch((error: any) => {
                    errorLogging.setError(error.message);
                    console.log("Token error:", error);
                  });
                console.log("client token", currentToken);
              } else {
                console.log(
                  "No registration token available. Request permission to generate one."
                );
              }
            })
            .catch((err: any) => {
              errorLogging.setError(err.message);
              console.log("An error occurred while retrieving token. ", err);
            });
        } else if (permission === "denied") {
          console.log("Permission for push notifications denied");
        }
      })
      .catch(error => {
        errorLogging.setError(error.message);
        console.log(error);
      }); */
  },
  getMessages: function(ref: any) {
    const msg = firebase.messaging();
    msg.onMessage((m: any) => {
      ref.value.push(m);
    });
  }
};
