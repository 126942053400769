import App from "@/app.vue";
import dk from "@/dk.json";
import en from "@/en.json";
import { createI18n } from "vue-i18n";
import "./serviceworker";
import router from "./routes-app";
import "@/assets/tailwind.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { authentication } from "./global/services/authentication";
import { farm } from "./global/services/farm";
import { units } from "./global/services/units";
import { analytics } from "@/global/services/analytics";
import { notifications } from "@/global/services/notifications";
import { employee } from "./global/services/employee";
import { errorLogging } from "./global/services/errorLogging";

const productionConfig = {
  apiKey: "AIzaSyCwytVbFlBbQEEuDUqEmPwusOy297ICtX8",
  authDomain: "farmhelp-bfe75.firebaseapp.com",
  projectId: "farmhelp-bfe75",
  storageBucket: "farmhelp-bfe75.appspot.com",
  messagingSenderId: "993713692327",
  appId: "1:993713692327:web:ff2f3c9155544d4f580582",
  measurementId: "G-XC18B1T8NL"
};

const demoConfig = {
  apiKey: "AIzaSyBq8xwZNxETNIjeIcLKdA8DZdEfm3ZPta0",
  authDomain: "farmhelp-demo.firebaseapp.com",
  projectId: "farmhelp-demo",
  storageBucket: "farmhelp-demo.appspot.com",
  messagingSenderId: "5498059801",
  appId: "1:5498059801:web:f1d8ff34c8fec11afe7e88"
};

const developmentConfig = {
  apiKey: "AIzaSyAlGYaGzX55CTv9B_a5pKuIAncBRvztdoE",
  authDomain: "farmhelp-dev.firebaseapp.com",
  projectId: "farmhelp-dev",
  storageBucket: "farmhelp-dev.appspot.com",
  messagingSenderId: "712079759455",
  appId: "1:712079759455:web:ed7963b950b60fa6fa23ea"
};

firebase.initializeApp(developmentConfig);

window.onerror = function(message, source, lineno) {
  errorLogging.setError(
    message.toString(),
    source,
    "Global",
    lineno?.toString()
  );
};

let app: any;
const i18n = createI18n({
  legacy: false,
  messages: {
    dk: dk,
    en: en
  },
  locale: "dk"
});

firebase.auth().onAuthStateChanged(user => {
  if (user !== null) {
    const usersRef = firebase.firestore().collection("users");
    usersRef.doc(user.uid).onSnapshot((docSnap: any) => {
      if (
        i18n.global.locale.value !== docSnap.data().lang &&
        docSnap.data().lang !== undefined
      ) {
        i18n.global.locale.value = docSnap.data().lang;
      }
      authentication.updateUserProfile(docSnap.data());
      employee.update(user.uid);
    });
    user
      .getIdTokenResult()
      .then(token => {
        authentication.updateClaims(token);

        if (process.env.NODE_ENV === "development") {
          for (const [key, value] of Object.entries(token.claims)) {
            console.log(`${key}: ${value}`);
          }
        }
      })
      .then(() => {
        if (authentication.getFarmId()) {
          farm.init(authentication.getFarmId()).then(() => {
            if (authentication.getFarmId()) {
              units.init();
              analytics.init();
              notifications.init();
            }
            if (!app) {
              import(/* webpackExports: ["default", "createApp"] */ "vue").then(
                module => {
                  console.log("Starting UI");
                  const { createApp } = module;
                  app = createApp(App)
                    .use(router)
                    .use(i18n)
                    .mount("#app");
                }
              );
            }
          });
        }
      });
  } else if (!app) {
    import(/* webpackExports: ["default", "createApp"] */ "vue").then(
      module => {
        console.log("Starting UI");
        const { createApp } = module;
        app = createApp(App)
          .use(router)
          .use(i18n)
          .mount("#app");
      }
    );
  }
});
