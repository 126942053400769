import { ref } from "vue";
import { authentication } from "@/global/services/authentication";
import { db } from "@/global/services/db";

const error = ref<string[]>([]);
const success = ref<string[]>([]);
const info = ref<string[]>([]);

export const errorLogging = {
  getErrors: function() {
    return error.value;
  },
  getSuccess: function() {
    return success.value;
  },
  getInfo: function() {
    return info.value;
  },

  /**
   * Register system error and send to analytics
   * @param message Error message
   * @param source File where error is located
   * @param component Component name
   * @param note Additional information
   */
  setError: function(
    message: string,
    source = "undefined",
    component = "Global",
    note = ""
  ) {
    if (location.hostname === "app.farmhelp.dk") {
      errorLogging.setAnalytics({
        error: true,
        message,
        component,
        source,
        note
      });
    }

    error.value.push("Something went wrong. We have registered the error.");
    setTimeout(() => error.value.shift(), 5000);
    
    console.log(message, component, source, note);
  },

  /**
   * Show warning message in UI and send to analytics
   * @param warningMessage Warning message shown in UI
   */
  setWarning: function(warningMessage: string) {
    if (location.hostname === "app.farmhelp.dk") {
      errorLogging.setAnalytics({ warning: true, message: warningMessage });
    }
    console.warn(warningMessage);
    error.value.push(warningMessage);
    setTimeout(() => error.value.shift(), 5000);
  },

  /**
   * Show success message in UI
   * @param successMessage Success message shown in UI
   */
  setSuccess: function(successMessage: string) {
    success.value.push(successMessage);
    setTimeout(() => success.value.shift(), 5000);
  },

  setInfo: function(infoMessage: string) {
    info.value.push(infoMessage);
    setTimeout(() => info.value.shift(), 5000);
  },
  setAnalytics: function(analyticsData: any) {
    console.log("Saving analytics");
    const data = Object.assign({}, analyticsData);
    data.fid = authentication.getFarmId() ? authentication.getFarmId() : null;
    data.uid = authentication.getUserUid() ? authentication.getUserUid() : null;
    db.createDocument(data, "analytics");
  }
};
